import React, { useState, useEffect, useContext } from "react";
import "./Product_Cart_Inner.css";
import { Breadcrumb, Button, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Product_Details_Div from "../Common_Component/Product_Details_Div/Product_Details_Div";
import Grand_Total_Table from "../Common_Component/Grand_Total_Table/Grand_Total_Table";
import Heart_Component from "../Common_Component/Heart_Component/Heart_Component";
import { faCartShopping, faTrash } from "@fortawesome/free-solid-svg-icons";
import Heading_Holder from "../Common_Component/Heading_Holder/Heading_Holder";
import Incre_Decre_btn from "../Common_Component/Incre_Decre_btn/Incre_Decre_btn";
import BreadcrumComponent from "../Common_Component/BreadcrumComponent/BreadcrumComponent";
import {
  CartQuantity,
  RemoveToCart,
  getCart,
} from "../../utils/apis/addToCart/addToCart";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
function Product_Cart_Inner() {
  const [numberOne, setNumberOne] = useState(1);
  const [numberTwo, setNumberTwo] = useState(1);

  var [totalPrice, setTotalPrice] = useState(0);
  var [totalTax, setTotalTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const navigate = useNavigate();
  const { IMG_URL, signin, sellerId, sellerDomain } = useContext(Context);
  const [cart, setCart] = useState([]);
  const cartGet = async () => {
    const res = await getCart(sellerId, sellerDomain);
    if (res?.success) {
      setCart(res?.data);
      res?.data?.map((d) => {
        setValue(getValues("") * d.mrp * d.quantity);
      });
    }
  };

  const removeFromCart = async (id) => {
    await RemoveToCart(id);
    cartGet();
  };

  console.log("cart",cart)

  const breadcrumbItems = [
    { text: "Home", link: "/" },
  ];

  useEffect(() => {
    if (signin && sellerId) {
      cartGet();
    } else {
      navigate('/login');
    }
  }, [sellerId, signin]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      total_amount: 0,
      discount: 0,
      tax: 0,
      shipping: 0,
      total: 0,
    },
  });

  return (
    <>
      {cart.length === 0 ? (
        <>
          {/* empty cart section start */}
          < section className="Empty_Cart">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-5 col-md-6 col-sm-7 col-10">
                  <div className="shop-now-content">
                    <div className="img-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Product Description/basket.png"
                        }
                        className="basket_img"
                      />
                    </div>
                    <div className="heading_holder mt-3">
                      <h5>Your cart is Empty</h5>
                      <p>
                        You have no items in your shopping cart. Let's go buy
                        something
                      </p>
                      <div className="main-btn-div">
                        <Link to={"/"}>
                          <button className="back-btn" type="button">Shop Now</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section >
          {/* empty cart section end */}
        </>
      ) : (
        <>
          <div className="Product_Cart ">
            <div className="container">
              <BreadcrumComponent items={breadcrumbItems} />
              <div className="row">
                <div className="col-xxl-9 col-xl-9 col-lg-8">
                  <div className="product_table">
                    <div className="Heading_text_Holder">
                      <Heading_Holder
                        headingText={
                          <>
                            {" "}
                            <FontAwesomeIcon
                              className="Cart_icon"
                              icon={faCartShopping}
                            />
                            In My Cart
                          </>
                        }
                      />
                    </div>
                    {cart?.map((post, index) => {
                      totalPrice += post?.selling_price * post.quantity;
                      // totalTax += (value?.product?.product_detail?.tax_percentage?.name * (post?.selling_price * post.quantity)) / 100;
                      return (
                        <div className="row tabless mb-4">
                          <div className=" col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-5 col-12 ">
                            <div className="productimg">
                              <img
                                src={IMG_URL + post.thumbnail}
                                className="cartimg"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-9 col-sm-7 col-12 ">
                            <div className="firstRow">
                              <Product_Details_Div
                                pageType={"checkout"}
                                subheading={post.product_title}
                                spanText={post?.product_generic_name}
                                productDescription={post?.product_generic_name}
                                Price={post?.selling_price}
                                subPrice={post.mrp}
                              />

                              <div className="prices">
                                <p className="prce">
                                  <Heart_Component id={post.cart_id} />
                                </p>
                                <p className="prce">
                                  <FontAwesomeIcon
                                    className="trash_icon"
                                    onClick={() => removeFromCart(post.cart_id)}
                                    icon={faTrash}
                                  />
                                </p>
                              </div>
                            </div>

                            <div className="B2b-Seller-Detail">
                              <div className="Background-image">
                                <div className="product-information">
                                  <div className="">
                                    <div className="subText_holder">
                                      <p className="subText">{post.varint_type}</p>
                                    </div>
                                    {/* <div className="sizeSection">
                                <div className="sizeNumber button_slide slide_down active">
                                  38
                                </div>
                                <div className="sizeNumber button_slide slide_down">
                                  39
                                </div>
                                <div className="sizeNumber button_slide slide_down">
                                  40
                                </div>
                                <div className="sizeNumber button_slide slide_down">
                                  41
                                </div>
                                <div className="sizeNumber button_slide slide_down">
                                  42
                                </div>
                                <div className="sizeNumber button_slide slide_down">
                                  43
                                </div>
                                <div className="sizeNumber button_slide slide_down">
                                  44
                                </div>
                              </div> */}
                                  </div>

                                  <div className="">
                                    <div className="subText_holder">
                                      <p className="subText">QUANTITY</p>
                                    </div>

                                    <Incre_Decre_btn
                                      quantity={post.quantity}
                                      id={post.cart_id}
                                      cartGet={cartGet}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <Link to={"/"}>
                    <div className="Continue_Shopping_text">
                      <p>Continue Shopping</p>
                    </div>
                  </Link>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-4">
                  <Grand_Total_Table cart={cart} totalPrice={totalPrice} discount={discount} totalTax={totalTax} />
                </div>
              </div>
            </div >
          </div >
        </>
      )
      }

    </>
  );
}

export default Product_Cart_Inner;
