import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./LogoutModal.css";
import Cookies from "js-cookie";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

const LogoutModal = (props) => {
  const { setSignin, setUsertype, setUserData, setSellerDomain, setDomainData, setrenderProducttable } = useContext(Context);
  const navigate = useNavigate();

  const logout = async () => {

    console.log("logout",props.btnname === "Logout")

    if (props.btnname === "Logout") {
      Cookies.remove('outer_website_security');
      setSignin(false);
      navigate('/');
      props.onHide();

    } 
  };
  return (
    <>
      <section className="Logout_modal">
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Logout_modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pt-0">
            <Modal.Title id="contained-modal-title-vcenter">
              {props.title}
            </Modal.Title>

            <div className="row mt-4">
              <div className="col-lg-6">
                <div className="text-center">
                  <button
                    type="button"
                    className="mb-2 Cancelbtn"
                    onClick={props.onHide}
                  >
                    Cancel{" "}
                  </button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="text-center">
                  <button
                    type="button"
                    className="mb-2 logoutdbtn"
                    onClick={logout}
                  >
                    {props.btnname}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default LogoutModal;


// import React from "react";
// import "./Password_Modal.css";
// import { Modal } from "react-bootstrap";
// import Successfull_Lottie from "../../Animation/Successfull_Lottie/Successfull_Lottie";

// function LogoutModal(props) {
//   return (
//     <>
//       <div className="Successfull_Modal_sec">
//         <Modal
//           className="Successfull_Modal"
//           {...props}
//           size="sm"
//           aria-labelledby="contained-modal-title-vcenter"
//           centered
//         >
//           <Modal.Header closeButton></Modal.Header>
//           <Modal.Body>
//             <div className="lottie-holder">
//               {" "}
//               <Successfull_Lottie />
//             </div>

//             <p>Account Created SuccessFully...!</p>
//           </Modal.Body>
//         </Modal>
//       </div>
//     </>
//   );
// }

// export default LogoutModal;
