import React, { useState, useEffect, useContext } from "react";
import "./Terms_and_Conditions.css";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { GetAllWebsiteFaq } from "../../utils/apis/master/Master";
import { Context } from "../../utils/context";
const Terms_and_Conditions = () => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const { getData, IMG_URL, Select2Data, sellerId, signin, sellerDomain } = useContext(Context);
  const [faq, setFaq] = useState([]);


  const getFaq = async () => {
    const res = await GetAllWebsiteFaq(sellerDomain);
    if (res?.success) {
      setFaq(res.data);
    }
  };

  useEffect(() => {
    getFaq();
  }, [sellerDomain]);



  return (
    <>
      <section className="faq">
        <Container>
          <Row>
            <Col md={11} className="mx-auto">
              <div className="faq-banner">
                <img
                  src={process.env.PUBLIC_URL + "/assets/faq/faq-banner.png"}
                  className="faq-img"
                />
              </div>
            </Col>
            <Col md={10} className="mx-auto">
              <div className="faq-holder ">
                <h1>Terms of Use</h1>
                <p>
                  Kindly check the FAQ below if you are not very familiar with
                  the functioning of this website. If your query is of urgent
                  nature and is different from the set of questions then please
                  contact us at: localmandaisupport@gmail.com
                </p>
              </div>
              {faq?.map((value, faqindex) => (
                <>
                  <div className="registration-box mt-5">
                    <h2>{value?.name}</h2>
                  </div>
                  {value?.s_sub_faqs?.map((subValue, subIndex) => (
                    <Accordion>
                      {/* {value?.s_faq?.map((accords, index) => ( */}
                      <Accordion.Item eventKey={faqindex}>
                        <Accordion.Header>{subValue?.name}</Accordion.Header>
                        <Accordion.Body>
                          <p> {htmlToReactParser.parse(subValue?.description)}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* ))} */}
                    </Accordion>
                  ))}


                </>
              ))}

              {/* <div className="registration-box mt-5">
                <h2>Account Related</h2>
              </div>
              <Accordion defaultActiveKey={5}>
                {accordsecond.map((accords) => (
                  <Accordion.Item eventKey={accords.eventskeys}>
                    <Accordion.Header>{accords.headings}</Accordion.Header>
                    <Accordion.Body>
                      <p> {accords.bodytext}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion> */}

              {/* <div className="registration-box mt-5">
                <h2>Payment</h2>
              </div>
              <Accordion defaultActiveKey={8}>
                {accordthird.map((accords) => (
                  <Accordion.Item eventKey={accords.eventskeys}>
                    <Accordion.Header>{accords.headings}</Accordion.Header>
                    <Accordion.Body>
                      <p> {accords.bodytext}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Terms_and_Conditions;
