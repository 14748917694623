import React, { useContext } from "react";
import "./Product_Swiper.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Banner_Card from "../Banner_Card/Banner_Card";
import Heart_Component from "../Heart_Component/Heart_Component";
import Product_Details_Div from "../Product_Details_Div/Product_Details_Div";
import Color_Cart_Holder from "../Color_Cart_Holder/Color_Cart_Holder";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

function Product_Swiper({
  sliderImages,
  breakpoints,
  slidesPerView,
  categoryActive,
}) {
  const { IMG_URL } = useContext(Context);

  const navigate = useNavigate();

  return (
    <>
      <div className="Product_Swiper ">
        <Swiper
          className="mySwiper menTabSwiper"
          spaceBetween={15}
          slidesPerView={slidesPerView}
          // pagination={{ clickable: true }}
          // modules={[Pagination]}
          breakpoints={breakpoints}
        >
          {sliderImages?.map(
            (slide, index) =>
              categoryActive === slide.s_category_id && (
                <SwiperSlide key={index}>
                  <Banner_Card
                    product={slide}
                    imgClassName="Tab_Slider_Img"
                    // detailsLink={slide.detailsLink}
                    imgSource={IMG_URL + slide.thumbnail}
                    heartClassName={"Heart_Like"}
                    HeartLikeComponent={<Heart_Component id={slide.id} />}
                    ProductDetailsClassName={"Product_Details"}
                    ProductDetailsSection={
                      <Product_Details_Div
                        product={slide}
                        // ColorCartHolder={<Color_Cart_Holder />}
                        subheading={slide?.product_title}
                        spanText={slide?.product_generic_name}
                        // productDescription={slide?.product_title}
                        Price={slide?.mrp}
                        subPrice={slide?.selling_price}
                      />
                    }
                  />
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
    </>
  );
}

export default Product_Swiper;
