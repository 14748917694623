import Cookies from "js-cookie";
import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

import { Context } from "../../context";
import { useContext } from "react";

import { getProductDetail } from "../Product/Product";

export const loginCart = async () => {
  let cartItems = [];

  const existingCart = Cookies.get("outer_website_cart");
  if (existingCart) {
    cartItems = JSON.parse(existingCart);
  }
  if (cartItems.length > 0) {
    await Promise.all(
      cartItems.map(async (value) => {
        await postData(`/outer-website/cart/add`, {
          product_id: value?.id,
        });
      })
    );
    await Cookies.remove("outer_website_cart");
  }
};

export const AddToCart = async (data) => {
  try {
    const res = await postData(`/outer-website/cart/add`, data);
    return res;
    // if (Cookies.get("outer_website_security")) {
    //   const res = await postData(`/outer-website/cart/add`, {
    //     product_id: id,
    //     seller_id: seller_id,
    //   });
    //   return true;
    // } else {
    //   let cartItems = [];
    //   const existingCart = Cookies.get("outer_website_cart");
    //   if (existingCart) {
    //     cartItems = JSON.parse(existingCart);
    //   }
    //   const res = await getProductDetail(id);
    //   if (res?.success) {
    //     console.log(res?.data, "no");
    //     const existingItemIndex = cartItems.findIndex((item) => item.id === id);
    //     if (existingItemIndex === -1) {
    //       const data = {
    //         id: id,
    //         name: res?.data?.name,
    //         image: res?.data?.product_images[0]?.image,
    //         price: res?.data?.s_price,
    //         tax_percent: res?.data?.product_detail?.tax_percentage?.name,
    //         quantity: 1,
    //       };
    //       cartItems.push(data);
    //       Cookies.set("outer_website_cart", JSON.stringify(cartItems));
    //     }
    //   }
    // }
    // return true;
  } catch (error) {
    console.log(error);
  }
};

export const RemoveToCart = async (id) => {
  try {
    const res = await getData(`/outer-website/cart/remove?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const CartQuantity = async (id, type) => {
  try {
    // if (Cookies.get("outer_website_security")) {
    const res = await postData(`/outer-website/cart/quantity`, {
      cart_id: id,
      type: type,
    });
    return res;
    // } else {
    //   let cartItems = [];

    //   const existingCart = Cookies.get("outer_website_cart");
    //   if (existingCart) {
    //     cartItems = JSON.parse(existingCart);
    //     Cookies.remove("outer_website_cart");
    //   }

    //   const existingItemIndex = cartItems.findIndex((item) => item.id === id);
    //   if (existingItemIndex === -1) {
    //   } else {
    //     if (cartItems[existingItemIndex]?.quantity === 1 && type === "minus") {
    //       cartItems.splice(existingItemIndex, 1);
    //     } else {
    //       if (type === "minus") {
    //         cartItems[existingItemIndex].quantity -= 1;
    //       } else {
    //         cartItems[existingItemIndex].quantity += 1;
    //       }
    //     }
    //     Cookies.set("outer_website_cart", JSON.stringify(cartItems));
    //   }
    // }

    // return true;
  } catch (error) {
    console.log(error);
  }
};

export const getCart = async (sellerId, domain_id) => {
  try {
    return await getData(`/outer-website/cart/show?seller_id=${sellerId}&domain_id=${domain_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getCartCheck = async (id,s_p_v_a_d_id,s_p_v_a_one_d_a_d_id,s_p_v_a_o_d_a_d_a_d_id, seller_id, domain_id) => {
  try {
    return await getData(
      `/outer-website/cart/getCart?id=${id}&s_p_v_a_d_id=${s_p_v_a_d_id}&s_p_v_a_one_d_a_d_id=${s_p_v_a_one_d_a_d_id}&s_p_v_a_o_d_a_d_a_d_id=${s_p_v_a_o_d_a_d_a_d_id}&seller_id=${seller_id}&domain_id=${domain_id}`
    );
    // if (Cookies.get("outer_website_security")) {
    //   return await getData(
    //     `/outer-website/cart/getCart?${id}`
    //   );
    // } else {
    //   let cartItems = [];

    //   const existingCart = Cookies.get("outer_website_cart");
    //   if (existingCart) {
    //     cartItems = JSON.parse(existingCart);
    //   }

    //   const existingItemIndex = cartItems.findIndex((item) => item.id == id);

    //   if (existingItemIndex === -1) {
    //   } else {
    //     return { success: true, cartItem: cartItems[existingItemIndex] };
    //   }
    // }
  } catch (error) {
    console.log(error);
  }
};
