import React, { useState, useEffect, useContext } from "react";
import "./Home_Banner.css";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import { GetAllHomeBanner } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import { Category } from "../../../utils/apis/Product/Product";
import { GetAllShopByCategory } from "../../../utils/apis/master/Master";
import { get } from "lodash";

function Home_Banner() {
  const { sellerId, IMG_URL, sellerDomain } = useContext(Context);
  const [homeBanner, setHomeBanner] = useState([]);

  const fetchHomeBanner = async () => {
    if(sellerDomain !== ""){
    const response = await GetAllHomeBanner(sellerDomain);
    if (response?.success) {
      setHomeBanner(response.data);
    }
  }
  };

  useEffect(() => {
    fetchHomeBanner();
  }, [sellerDomain]);

  const [categories1, setCategories1] = useState();
  const [categories2, setCategories2] = useState();
  const [categories3, setCategories3] = useState();

  console.log("sellerDomain", sellerDomain);


  const getCategory = async () => {
    const res = await Category(sellerId);
    if (res?.success) {
      // console.log(res?.data,'llll');
      const data = res?.data;

      if (data[0]) {
        setCategories1(data[0]);
      }
      if (data[1]) {
        setCategories2(data[1]);
      }
      if (data[2]) {
        setCategories3(data[2]);
      }
    }
  };

  console.log(categories1);

  useEffect(() => {
    fetchHomeBanner();
    getCategory();
  }, [sellerId]);
  return (
    <>
      <div className="Home_Banner">
        <div className="container">
          <div className="Home_Banner_Card_Holder">
            <div className="row ms-0 ms-0">
              <div className="col-lg-6 col-xl-7 col-md-7">
                <div className="Home_Banner_Card_Slider_Holder">
                  <Swiper
                    className="mySwiper"
                    spaceBetween={1}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                  >
                    {homeBanner.map((slide, index) => (
                      <SwiperSlide key={index}>
                        <Banner_Card
                          imgClassName={"bannerImg"}
                          imgSource={IMG_URL + slide.image}
                          detailsLink={slide.detailsLink}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>

              <div className="col-lg-6 col-xl-5 col-md-5">
                <div className="row">
                  <div className="col-md-6 col-6">
                    <div className="Home_Banner_Small_Card">
                      <div className="row">
                        {categories1 && (
                          <div className="col-md-12">
                            <Link to={"/our_products_page"}>
                              <div className="smallcardban">
                                <Banner_Card
                                  className="small_card"
                                  imgClassName="Home_Banner_Small_Card_img"
                                  imgSource={IMG_URL + categories1?.image}
                                />
                                <div className="overlayss"></div>
                              </div>
                            </Link>
                          </div>
                        )}
                        {categories2 && (
                          <div className="col-md-12">
                            <Link to={"/our_products_page"}>
                              <div className="smallcardban">
                                <Banner_Card
                                  className="smallcard2"
                                  imgClassName="Home_Banner_Small_Card_img"
                                  imgSource={IMG_URL + categories2?.image}
                                />
                                <div className="overlayss mx-auto"></div>
                              </div>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {categories3 && (
                    <div className="col-md-6 col-6">
                      <Link to={"/our_products_page"}>
                        <div className="Home_Banner_Long_Card">
                          <div className="longcardban">
                            <Banner_Card
                              imgClassName="Home_Banner_Long_Card_img"
                              imgSource={IMG_URL + categories3?.image}
                            />
                            <div className="overlayss mx-auto"></div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home_Banner;
